import { setCMSBarWidth } from './cms-bar-width'


window.addEventListener('DOMContentLoaded', () => {
    document.getElementsByTagName("BODY")[0].classList.remove("jsdisabled")
    for (const elem of document.getElementsByClassName("topcheckform")) {
        elem.style.display = 'block'
    }

    const structure = document.querySelector("a[href='?structure']")
    if (structure) {
        structure.addEventListener('click', () => {setTimeout(setCMSBarWidth, 1000)}, true)
    }

    const toolbar_link = document.querySelector(".cms-toolbar-item-logo a")
    if (toolbar_link) {
        toolbar_link.after(document.body.dataset.project_version)
    }

}, false)
